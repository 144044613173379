//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode } from '@/api/organizational'
import axios from 'axios'

//获取省市区三级联动数据接口
import {
  _getProvinceList,
  _getProvinceCityList,
  _getCityCountyList,
  _getSubmitUser,
  _getgetinfo,
  _download,
  _getUniqueName,
  _getStarRate,
  _getStarRateRevise,
  _getstarRateDelete,
  _getStarRateDownload,
  _getStarRatePlatformStarRateInfo,
  _getStarRatePlatformStarRateListByPage,
  _getSubmitYearCheck
} from '@/api/province'
import DownloadDialog from './DownloadDialog.vue'
export default {
  data() {
    return {
      dialogVisible: false,
      textbtn: '审核',
      ssss: '',
      dataAdd: [],
      formInline: {
        name: '', //主体名称
        organType: '', //主体机构类型
        address: '', //详细地址
        contactName: '', //联系人姓名
        contactPhone: '', //联系人手机号
        code: '', //社会信用代码
        regionCode: '', //市
        provinceid: '', //活动区域
        cityid: '', //省
      },
      suffix: '',
      ossIds: [], //提交的时候图片的ossid数组
      cityCountylist: [],
      provinceCitylist: [],
      provinceList: [],
      imgsList: [], // 文件上传列表
      newimgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      disab: false, //其他禁用状态
      disabstar: false, //星级评定禁用状态
      roletype: '', //角色
      rules: {},
      fileList: [], //文件数组
      curFileList: [], //ossid存储数组
      form: {
        opComment: '', //附件备注
      },
      shows: false, //星级评定是否显示
      tableDatass: [],
      formInlines: {
        years: null,
      },
      years: null,
      isCloudes: false,
      yearsbtn: null,
      showUp: [],
      btndisabled : false,//控制表格里面按钮的禁用
      yearlist : [],//年度列表
      deptid : ''
    }
  },

  created() {
    this.roletype = JSON.parse(localStorage.getItem('roleType'))
    this.getcode()
    //获取省的数据
    _getProvinceList({}).then((res) => {
      this.provinceList = res.data
    })
    // axios
    //   .get(
    //     `/ananops/api/v1/pub/file/download?ossId=f31c8f6120644e72840b029a117f7338&type=pc&opration=inline`,
    //   )
    //   .then((res) => {
    //     console.log(res)
    //   })
    // 回显数据
    _getgetinfo({}).then((res) => {
      if (res.code == '1') {
        if (res.data == null || res.data.checkStatus == null) {
          this.disab = false
        }
        // 审核中
        else if (res.data.checkStatus == '0') {
          this.formInline = Object.assign(res.data)

          this.formInline.organType = res.data.organType
          this.disab = true
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
        } else if (res.data.checkStatus == '1') {
          this.formInline = Object.assign(res.data)
          this.disab = true
          this.shows = true
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
          this.deptid = res.data.id
          //星级评定回显数据
          _getStarRatePlatformStarRateListByPage({ deptId: res.data.id }).then(
            (resq) => {
              if (resq.code == 1) {
                let data = resq.data.data
                console.log(data);
                data.forEach((item) => {
                  let enclosures = item.enclosures.map((arr) => {
                    return {
                      ...arr,
                      useful: 1,
                    }
                  })
                  item.enclosures = enclosures
                })
                this.tableDatass = data
              }
            },
          )
        } else if (res.data.checkStatus == '2') {
          this.formInline = Object.assign(res.data)
          this.disab = false
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
        }
        console.log(this.formInline, this)
      }
    })
  
    let newyear = new Date().getFullYear()
    console.log(newyear);
    this.yearlist = [
      {label:String(newyear),value:String(newyear)},
      {label:String(newyear+1),value:String(newyear+1)},
    ]
  },
  components: {
    DownloadDialog,
  },
  methods: {
    onSubmit() {
      console.log('submit!')
    },
    downloadMode() {
      this.$refs.DownloadDialog.open()
      // window.alert('点击模板下载')
      // let arr = [
      //   'f3c46cfe3e174f3ca1f983c658fe999f',//附表一
      //   '918b8dd9dd464a8c87357305de23bb56',//附表二
      //   '067584086ed84b068dd18cbc08f0b5b9',//附表三
      //   'fd2b081a316846018aab2177f15c8f73',//附表四
      //   "68e8881d2d29461989683d77f00ab6d5",//总的压缩包
      // ]

      // arr.forEach((item,i) => {
      //   setTimeout(() => {
      //     const a = document.createElement('a')
      //     // f631bbe9ebc249eaa6362aadd305e266   word
      //     a.href = `/ananops/api/v1/pub/file/download?ossId=${item}&type=pc&opration=download`
      //     // a.target='_blank'
      //     console.log(item, a)
      //     a.click()
      //   },500 *i)
      // })
    },
    wordDetail() {
      // 微软提供的方法
      // window.open('http://api.bjwh.org.cn/uploads/20220130/b47bbfc8d7dcc907ffc25b80f8273a1c.PDF')
      //  window.open('https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc')
      //  xmlHttp.EditDocument("http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId=f631bbe9ebc249eaa6362aadd305e266&type=pc");
      // window.open('https://view.officeapps.live.com/op/view.aspx?src=http://mczaiyun.top/ht/3.docx')
      // window.open('http://130.10.7.48:8002/ananops/api/v1/pri/supervision/exportreport')
      // window.open(
      //   'http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId=f631bbe9ebc249eaa6362aadd305e266&type=pc',
      //   // 'http://130.10.7.107:9001/ananops/%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E5%88%97%E8%A1%A8.xls?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minioadmin%2F20220222%2F%2Fs3%2Faws4_request&X-Amz-Date=20220222T033734Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=398ae9530e00c0e9c55bc0ae36c85c01a023af9fc67206eb94873ef92bd6a5af'
      // )
    },
    changeProvince(e) {
      //获取市的数据
      _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
        (res) => {
          this.provinceCitylist = res.data
        },
      )
    },
    changeProvinceCity(e) {
      //获取区的数据
      _getCityCountyList({ parent_id: this.formInline.cityid }).then((res) => {
        this.cityCountylist = res.data
      })
    },
    //改变下拉框年份
    changeYears(e) {
      // console.log(e)
      _getSubmitYearCheck({deptId : this.deptid,year :e }).then(res => {
        if(!!res){
          if (e) {
            this.showUp = [e]
            let isCloudes = false
            isCloudes =
              (this.tableDatass.length &&
                this.tableDatass.every((item) => {
                  return item.year != e
                })) ||
              this.tableDatass.length == 0
            // console.log(isCloudes)
            this.isCloudes = isCloudes
            this.years = e
            if (isCloudes) {
              this.tableDatass.push({
                year: e,
                level: null,
                opComment: '',
                useful: 0,
                enclosures: [],
                result: -1,
              })
            } else {
              // this.$message.warning(`请先点击修改，之后`)
              return
            }
          }
        }
      })
      
    },
    // 审核按钮
    submitUsers(e) {
      console.log(e)
      this.showUp = [e.year]
      // console.log(this.curFileList)
      if (e.enclosures.length) {
        this.btndisabled = true;
        let enclosures = []
        e.enclosures.forEach((item) => {
          enclosures.push(item.id)
        })
        this.tableDatass.forEach((item) => {
          if (item.year == e.year) {
            let enclosures = item.enclosures
            enclosures = enclosures.map((arr) => {
              return {
                ...arr,
                useful: 1,
              }
            })
            item.enclosures = enclosures
            item.result = 0
            item.resultName = '待评级'
          }
        })
        let form = {
          enclosures,
          opComment: e.opComment,
          year: e.year,
        }
        _getStarRate(form).then((res) => {
          console.log(res)
          if (res.code == 1) {
            // this.disabstar = true
            
            this.tableDatass.forEach((item) => {
              if (item.year == e.year) {
                item.useful = 1
              }
            })
            this.$message('提交审核成功,请您耐心等待')
            this.btndisabled = false;
            this.$router.go(0);
          }else{
            this.btndisabled = false;
          }
        })
      } else {
        this.$message('请上传附件')
      }
    },
    //点击修改按钮
    changeUsersBtn(e) {
      console.log(e)
      this.showUp = [e.year]
      this.tableDatass.forEach((item) => {
        if (item.year == e.year) {
          let enclosures = item.enclosures
          enclosures = enclosures.map((arr) => {
            return {
              ...arr,
              useful: 0,
            }
          })
          item.useful = 0
          item.enclosures = enclosures
        }
      })
    },
    // 展开行
    clickTable(row, index, e) {
      console.log(row, index, e)
      this.$refs.refTable.toggleRowExpansion(row)
    },
    //确定修改星级评定 提交审核
    changeUsers(e) {
      console.log(e)
      // console.log(this.curFileList)
      this.showUp = [e.year]
      if (e.enclosures.length) {
        let enclosures = []
        e.enclosures.forEach((item) => {
          enclosures.push(item.id)
        })
        let form = {
          enclosures,
          opComment: e.opComment,
          year: e.year,
        }
        this.btndisabled = true;
        _getStarRateRevise(form).then((res) => {
          console.log(res)
          if (res.code == 1) {
            // this.disabstar = true

            this.tableDatass.forEach((item) => {
              if (item.year == e.year) {
                let enclosures = item.enclosures
                enclosures = enclosures.map((arr) => {
                  return {
                    ...arr,
                    useful: 1,
                  }
                })
                item.useful = 1
                item.enclosures = enclosures
              }
            })
            this.$message('提交审核成功,请您耐心等待')
            this.btndisabled = false;
            this.$router.go(0);
          }else{
            this.btndisabled = false;
          }
        })
      } else {
        this.$message('请上传附件')
      }
    },
    //点击删除
    deleteUsers(e) {
      console.log(e)
      // console.log(this.curFileList)
      // if (e.enclosures.length) {
      // let enclosures = []
      // e.enclosures.forEach((item) => {
      //   enclosures.push(item.id)
      // })
      let form = {
        // enclosures,
        // opComment: e.opComment,
        year: e.year,
      }
      this.btndisabled = true;
      _getstarRateDelete(form).then((res) => {
        console.log(res)
        if (res.code == 1) {
          // this.disabstar = true
          this.tableDatass = this.tableDatass.filter((item) => {
            return item.year != e.year
          })
          this.$message('删除成功')
          this.btndisabled = false;
          this.$router.go(0);
        }else{
          this.btndisabled = false;
        }
      })
      // } else {
      //   this.$message('请上传附件')
      // }
    },
    // 失焦事件
    blurs() {
      _getUniqueName({ name: this.formInline.name }).then((res) => {
        if (res.code == '1') {
          this.suffix = 'el-icon-success'
        } else {
          this.suffix = 'el-icon-error'
        }
      })
    },
    getcode() {
      let from = {
        para: 'jglx',
      }
      _getcode(from).then((res) => {
        this.dataAdd = res.data
      })
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 附件成功返回的数据
    successFile(response, file, fileList) {
      console.log(response, file, fileList, this.fileList)
      this.tableDatass = this.tableDatass.map((item) => {
        // console.log(item.year, this.years)
        if (item.year == this.years) {
          let enclosures = item.enclosures
          enclosures.push({
            name: file.name,
            id: response.data.ossId,
            useful: 0,
          })
          return {
            ...item,
            enclosures,
          }
        } else {
          return item
        }
      })
    },
    // 附件成功返回的数据
    successFiles(response, file, fileList) {
      console.log(response, file, fileList, this.fileList)
      this.tableDatass = this.tableDatass.map((item) => {
        // console.log(item.year, this.yearsbtn)
        if (item.year == this.yearsbtn) {
          let enclosures = item.enclosures
          enclosures.push({
            name: file.name,
            id: response.data.ossId,
            useful: 0,
          })
          return {
            ...item,
            enclosures,
          }
        } else {
          return item
        }
      })
      this.yearsbtn = null
      this.isCloudes = false
      // console.log(this.tableDatass)
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList)
    },
    handlePreview(file) {
      // console.log(file)
    },
    handleExceed(files, fileList) {
      // this.$message.warning(
      //   `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
      //     files.length + fileList.length
      //   } 个文件`,
      // )
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`)
    },
    changeYearsBtn(e) {
      // console.log(e)
      this.yearsbtn = e.year
      this.isCloudes = true
      this.showUp = [e.year]
    },
    beforeUpload(file) {
      console.log(file, '上传文件file')
      //这里判断  如果未在修改 或可操作状态   不让上传
      if (this.years) {
        let isCloudes =
          (this.tableDatass.length &&
            this.tableDatass.some((item) => {
              return item.year == this.years
            })) ||
          this.tableDatass.length == 0
        this.isCloudes = isCloudes
        if (!this.isCloudes) {
          this.$message.warning(`未选择年份或当前年份不可上传`)
          //终止上传操作
          this.$refs.upload.abort(file)
          return false
        }
      }

      if (file.type != 'application/pdf') {
        this.$message.warning(`请上传pdf文件`)
      }
      return file.type == 'application/pdf'
    },
    beforeUploads(file) {
      console.log(file, '上传文件file')
      //这里判断  如果未在修改 或可操作状态   不让上传
      if (!this.isCloudes) {
        this.$message.warning(`未选择年份或当前年份不可上传`)
        //终止上传操作
        this.$refs.upload.abort(file)
        this.isCloudes = false
        return false
      }
      //判断是否是pdf文件
      if (file.type != 'application/pdf') {
        this.$message.warning(`请上传pdf文件`)
      }
      return file.type == 'application/pdf'
    },
    handleClickLook(scope) {
      //点击查看
      // console.log(scope)
      // console.log(scope, scope.response.data.ossId)

      window.open(
        // f631bbe9ebc249eaa6362aadd305e266
        `/ananops/api/v1/pub/file/download?ossId=${scope.id}&type=pc&opration=inline`,
        //   //  `http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId= f631bbe9ebc249eaa6362aadd305e266&type=pc`,
        //   // 'http://130.10.7.107:9001/ananops/%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E5%88%97%E8%A1%A8.xls?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minioadmin%2F20220222%2F%2Fs3%2Faws4_request&X-Amz-Date=20220222T033734Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=398ae9530e00c0e9c55bc0ae36c85c01a023af9fc67206eb94873ef92bd6a5af'
      )

      //下面代码可以预览视频
      // let page = window.open();
      // let html = `<body style='background:black;width:90%;height:100%'><div style='width:50%;margin:auto;'><video controls width='50%' autoplay src=/ananops/api/v1/pub/file/download?ossId=${scope.id}&type=pc&opration=inline></video></div></body>`
      // page.document.write(html)
    },
    download(data) {
      // console.log(data.id)
      const a = document.createElement('a')
      // f631bbe9ebc249eaa6362aadd305e266   word
      a.href = `/ananops/api/v1/pub/file/download?ossId=${data.id}&type=pc&opration=download`
      a.click()
      // _getStarRateDownload({
      //   ossId: 'ad8876cb136f44e48f38647a0f5b1c76',
      //   type: 'pc',
      // }).then((res) => {
      //   console.log(res)
      //   // const link = document.createElement('a')
      //   const blob = new Blob([res], { type: 'application/pdf' })

      //   const a = document.createElement('a')
      //   // 利用URL.createObjectURL()方法为a元素生成blob URL
      //   a.href = URL.createObjectURL(blob)
      //   // 设置文件名，目前只有Chrome和FireFox支持此属性
      //   // const filename = window.vm.$cookies.get('filename')
      //   // const filename = '维修工单列表'
      //   // a.download = filename
      //   a.click()
      // })
    },
    deleteFile(scope) {
      // console.log(scope)
      this.tableDatass.forEach((item) => {
        item.enclosures = item.enclosures.filter((arr) => {
          return arr.id != scope.id
        })
      })
    },
  },
}
