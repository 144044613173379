//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      centerDialogVisible: false,
      tableData: [
        // {
        //   id: '68e8881d2d29461989683d77f00ab6d5',
        //   name: '安安运维平台星级服务商评价表模板',
        // },
        {
          id: 'f3c46cfe3e174f3ca1f983c658fe999f',
          // 600a949240b649f49a806b91023df210
          name: '附表一：安防系统运行维护服务星级评价申请表',
        },
        {
          id: '918b8dd9dd464a8c87357305de23bb56',
          // a37cdbb63c5b4f958402ac69889ca48a
          name: '附表二：安防系统运行维护服务岗位资格人员清单',
        },
        {
          id: '067584086ed84b068dd18cbc08f0b5b9',
          // 1c73fb9d3dc14d9a9beac09f9009e5c4
          name: '附表三：安防系统运行维护服务业绩表',
        },
        {
          id: 'fd2b081a316846018aab2177f15c8f73',
          // bc3a23a400d14ac9a3fb2eda7b4a1d47
          name: '附表四：安全防范系统运行维护服务用户评价表',
        },
        {
          id: '96acaa9976374794b0a17bc831327328',
          name: '安防服务星级评价管理办法（2022）.pdf',
        },
        {
          id: '820371bb52b64437ab4d740c31ca6513',
          name: '安防系统运行保障服务星级标准（征求意见稿）.pdf',
        },
      ],
      multipleSelection: [],
      disableDownload: false,
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    open() {
      this.centerDialogVisible = true
      this.disableDownload = false
     
      this.toggleSelection(this.tableData)
     
    },
    close() {
      this.centerDialogVisible = false
      this.disableDownload = false
      this.multipleSelection = []
      // console.log(this.multipleSelection)
       this.toggleSelection()
    },
    downloadMode() {
      console.log(this.multipleSelection)
      // window.alert('点击模板下载')
      // let arr = [
      //   'f3c46cfe3e174f3ca1f983c658fe999f',//附表一
      //   '918b8dd9dd464a8c87357305de23bb56',//附表二
      //   '067584086ed84b068dd18cbc08f0b5b9',//附表三
      //   'fd2b081a316846018aab2177f15c8f73',//附表四
      //   "68e8881d2d29461989683d77f00ab6d5",//总的压缩包
      // ]
      this.disableDownload = true
      this.multipleSelection.forEach((item, i) => {
        setTimeout(() => {
          const a = document.createElement('a')
          // f631bbe9ebc249eaa6362aadd305e266   word
          a.href = `/ananops/api/v1/pub/file/download?ossId=${item.id}&type=pc&opration=download`
          // a.target='_blank'
          // console.log(item, a)
          a.click()
          if (i == this.multipleSelection.length - 1) {
            this.disableDownload = false
          }
        }, 500 * i)
      })
    },
    toggleSelection(rows) {
      let _this = this
      if (rows) {
        // console.log(rows)
        //  console.log(this.multipleSelection)
        rows.forEach((row) => {
          // this.$nextTick().then(function () {
          //   _this.$refs.multipleTable.toggleRowSelection(row)
          // })
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    clickRow(row){
      // console.log(row)
      this.$refs.multipleTable.toggleRowSelection(row)
    }
  },
}
